<template>
  <b-form
    @submit.prevent="$emit('submit', model)"
    @keydown.enter="$emit('submit', model)"
  >
    <b-row>
      <b-col sm="12">
        <b-form-group>
          <label> Fecha entre </label>
          <b-row no-gutters>
            <b-col cols="6">
              <b-form-datepicker
                :value="model.date_min"
                class="margin-right-datepicker"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                size="sm"
                reset-button
                :hide-header="true"
                placeholder="Desde"
                :label-help="null"
                :max="model.date_max"
                @input="update('date_min', $event)"
                start-weekday="1"
              />
            </b-col>
            <b-col cols="6">
              <b-form-datepicker
                :value="model.date_max"
                class="margin-left-datepicker"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                size="sm"
                reset-button
                :hide-header="true"
                placeholder="Hasta"
                :label-help="null"
                :min="model.date_min"
                @input="update('date_max', $event)"
                start-weekday="1"
              />
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group>
          <label> Fecha vencimiento entre </label>
          <b-row no-gutters>
            <b-col cols="6">
              <b-form-datepicker
                :value="model.date_expiration_min"
                class="margin-right-datepicker"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                size="sm"
                reset-button
                :hide-header="true"
                placeholder="Desde"
                :label-help="null"
                :max="model.date_expiration_max"
                @input="update('date_expiration_min', $event)"
                start-weekday="1"
              />
            </b-col>
            <b-col cols="6">
              <b-form-datepicker
                :value="model.date_expiration_max"
                class="margin-left-datepicker"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                size="sm"
                reset-button
                :hide-header="true"
                placeholder="Hasta"
                :label-help="null"
                :min="model.date_expiration_min"
                @input="update('date_expiration_max', $event)"
                start-weekday="1"
              />
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>
      <b-col v-if="origin === 'invoice'" sm="12">
        <b-form-group label="Categoría" label-for="purchase-invoice-category">
          <CategoryGroupSelect
            id="purchase-invoice-category"
            :value="model.category_group"
            @input="update('category_group', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
          label="Nº Factura provedor"
          label-for="purchase-invoice-provider-code"
        >
          <b-form-input
            id="purchase-invoice-provider-code"
            :value="model.provider_code"
            name="purchase-invoice-provider-code"
            placeholder="Nº Factura provedor"
            @input="update('provider_code', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col v-if="origin === 'invoice'" sm="12">
        <b-form-group
          label="Nº Pedido"
          label-for="purchase-invoice-order-number"
        >
          <b-form-input
            id="purchase-invoice-order-number"
            :value="model.order_number"
            name="purchase-invoice-order-number"
            placeholder="Número pedido"
            @input="update('order_number', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col v-if="origin === 'invoice'" sm="5">
        <b-form-group
          label="Nº Albarán"
          label-for="purchase-invoice-order-number"
        >
          <b-form-input
            id="purchase-invoice-order-number"
            :value="model.order_number"
            name="purchase-invoice-order-number"
            placeholder="Albarán"
            @input="update('order_number', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="7">
        <BaseFormRadioGroup
          id="purchase-invoice-delivery-note-received"
          :value="model.delivery_note_received"
          label="Albaranes recibidos"
          :options="[
            { text: 'Si', value: 1 },
            { text: 'No', value: 0 },
          ]"
          @input="update('delivery_note_received', $event)"
        />
      </b-col>
      <b-col v-if="origin === 'invoice'" sm="12">
        <b-form-group label="División" label-for="purchase-invoice-division">
          <BaseSelect
            id="purchase-invoice-division"
            :value="model.division"
            :resource="$data.$constants.RESOURCES.RESOURCE_DIVISIONS"
            http-method="get"
            @input="update('division', $event)"
            :reduce="((item) => {return { id: item.id, name: item.name }})"
            :multiple="true"
          />
        </b-form-group>
      </b-col>
      <b-col v-if="origin === 'fra'" sm="12">
        <validation-provider
          v-slot="validationContext"
          name="Partida"
          rules="required"
        >
          <b-form-group label="Partida" label-for="purchase-invoice-item">
            <BaseSelect
              id="purchase-invoice-item"
              :resource="$data.$constants.RESOURCES.RESOURCE_ACCOUNTING_ITEMS"
              :value="model.accounting_item"
              :type="statusType"
              @input="update('accounting_item', $event)"
            />
            <small class="text-danger">{{ validationContext.errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col sm="12">
        <b-form-group label="Proveedor" label-for="purchase-invoice-provider">
          <BaseSelect
            id="purchase-invoice-provider"
            :resource="$data.$constants.RESOURCES.RESOURCE_PROVIDERS"
            :value="model.provider"
            @input="update('provider', $event)"
            :reduce="((item) => {return { id: item.id, name: item.name }})"
            :multiple="true"
          />
        </b-form-group>
      </b-col>
      <b-col v-if="origin === 'invoice'" sm="12">
        <b-form-group label="Job" label-for="purchase-invoice-job">
          <BaseSelect
            id="purchase-invoice-job"
            :resource="$data.$constants.RESOURCES.RESOURCE_JOBS"
            :value="model.job"
            @input="update('job', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group label="Estado" label-for="purchase-invoice-status">
          <StatusSelect
            id="purchase-invoice-status"
            :value="model.status"
            :type="statusType"
            @input="update('status', $event)"
            :reduce="((item) => {return { id: item.id, name: item.name }})"
            :multiple="true"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
          label="Envio documento de pago"
          label-for="purchase-invoice-received-payment-document"
        >
          <BaseSelect
            id="purchase-invoice-received-payment-document"
            :value="model.received_payment_document"
            :options="[
              { name: 'si', value: 1 },
              { name: 'no', value: 0 },
            ]"
            label="name"
            :reduce="(item) => item.value"
            @input="update('received_payment_document', $event)"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import FormMixin from "@/mixins/formMixin";
import { PURCHASE_INVOICES_STATUS_TYPE } from "@/api/status-api";
import CategoryGroupSelect from "@/components/category/select/CategoryGroupSelect.vue";
import StatusSelect from "@/components/status/select/StatusSelect.vue";
import BaseFormRadioGroup from "@/components/ui/radio/BaseFormRadioGroup.vue";
import BaseSelect from "@/components/ui/select/BaseSelect.vue";
import { mapGetters } from 'vuex'

export default {
  name: "PurchaseInvoicesListFiltersForm",
  components: {
    BaseSelect,
    BaseFormRadioGroup,
    StatusSelect,
    CategoryGroupSelect,
  },
  mixins: [FormMixin],
  data() {
    return {
      statusType: PURCHASE_INVOICES_STATUS_TYPE,
    };
  },
  computed: {
    ...mapGetters({
      tab: 'purchaseInvoice/getTabSelected',
      filters: 'purchaseInvoice/getFilters',
    }),
    origin () {
      return this.tab === 0 ? 'invoice' : 'fra'
    }
  },
};
</script>

<style scoped>
.margin-left-datepicker {
  margin-left: 5px;
}
.margin-right-datepicker {
  margin-right: 5px;
}
</style>
